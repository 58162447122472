<template>
  <section id="settings_avatar">
    <b-row>
      <b-col>
        <h1>{{ $t("setting.avatar_title") }}</h1>
        <h5 class="text-primary">
          {{ $t("setting.avatar_subtitle") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('defSet') }}</b-card-title>
            <b-button
              variant="success"
              @click="updateAvatars($event)"
            >
              {{ $t('dataGeneric.save') }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-overlay
              :show="show"
              variant="transparent"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-form>
                <b-row>
                  <b-col
                    cols="4"
                    md="3"
                    @dragover="event => event.preventDefault()"
                    @drop="addFiles($event)"
                  >
                    <label class="d-flex ">
                      <span>{{ $t('imagDef') }}</span>
                      <feather-icon
                        class="text-danger cursor-pointer ml-2"
                        icon="XOctagonIcon"
                        size="15"
                        @click="imageBol ? deleteImage('fileInput') : ''"
                      />
                    </label>
                    <b-avatar
                      class="mt-1 dropContainer"
                      size="18em"
                    >
                      <div
                        class="base-image-avatar image_avatar "
                        @click="chooseImage('fileInput')"
                      >
                        <span
                          v-if="!imageBol"
                          class="placeholder"
                        >
                          <feather-icon
                            icon="ImageIcon"
                            size="80"
                          />
                          <small>
                            {{ $t("media.dropFilesToCard") }}
                          </small>
                        </span>
                        <b-img
                          v-else
                          rounded="circle"
                          :src="imageSrc == null ? imageSelectedPlaceholder : imageSrc"
                          class="mb-3 image_avatar"
                          fluid
                          block
                        />
                        <b-form-file
                          id="fileInput"
                          v-model="imageSelected"
                          accept="image/*"
                          class="mr-1"
                          style="display: none"
                        />
                      </div>
                    </b-avatar>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col md="9">
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('tagsAva')"
                        >
                          <v-select
                            v-model="selected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            taggable
                            push-tags
                            :placeholder="$t('addTagsC')"
                            @input="modalTags()"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <feather-icon
                          v-b-tooltip.hover.top.v-primary="$t('addTags')"
                          icon="InfoIcon"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('randAvatar')">
                          <b-form-checkbox
                            v-model="isRandom"
                            switch
                          >
                            {{ $t('defAvatar') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>

import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BImg,
  VBTooltip,
  BOverlay,
  BAvatar,
  BForm,
  BFormFile,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { showToast, base64Encode, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BFormFile,
    BCardBody,
    BAvatar,
    vSelect,
    BImg,
    BFormGroup,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: false,
      id: null,
      name: '',
      description: '',
      imageShow: '',
      imageSrc: null,
      isRandom: false,
      imageBol: false,
      headers: {},
      imageSelectedPlaceholder: null,
      imageSelected: null,
      nameState: null,
      imageState: null,
      selected: [],
      idTags: [],
      originalTags: [],
      characters: [],
      userData: getUserData(),
    }
  },
  watch: {
    imageSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageBol = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    // eslint-disable-next-line no-empty
    } catch (error) {}
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getAvatars()
    const badge = document.getElementsByClassName('b-avatar-badge')
    badge.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.style = 'font-size: calc(0.80em);  top: 0.5em; right: 0.5em;'
    })
  },
  methods: {

    updateAvatars(event) {
      const { headers } = this
      this.show = true

      event.preventDefault()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation{
            updateClient(id:"${this.userData.profile.client.id}",input:{
              characterAvatarTags: [${this.idTags}],
              selectRandomAvatar: ${this.isRandom},
             
            }) {
              client{
                name
                id
              }
            }
          }
        `
      data.append('query', query)
      data.append('default_avatar_image', this.imageSelected)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.show = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
    chooseImage(imageClicked) {
      document.getElementById(imageClicked).click()
    },
    deleteImage() {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('remAvatar'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.show = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
            mutation{
            updateClient(id:"${this.userData.profile.client.id}",input:{
              videotype: CON                 

            }) {
              client{
                name
                id
              }
            }
          }
     `
            data.append('query', query)
            this.imageBol = false
            this.imageSelectedPlaceholder = ''
            this.imageSrc = null
            data.append('deleteFile', 'default_avatar_image')
            axios.post('', data, config).then(createResponse => {
              messageError(createResponse, this)
              this.show = false

              showToast(this.$t('success'), 1, this)
            }).catch(() => {
              this.show = false

              showToast(this.$t('code.updateDataError'), 2, this)
            })
          }
        })
        .catch(() => { })
    },
    addFiles(event) {
      event.dataTransfer.files.forEach(element => {
        this.imageSelected = element
      })

      event.preventDefault()
    },
    modalTags() {
      const { headers } = this

      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            extendedTags(name:"${this.selected[this.selected.length - 1]}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.extendedTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })
            if (crear) {
              /* AQUÍ CREA EL TAG */
              axios.post('', {
                query: `
              mutation{
                createExtendedTags(input:{name:"${this.selected[this.selected.length - 1]
}",client:"${this.userData.profile.client.id}"}){
                    extendedTag{
                        id
                        name
                    }
                }
            }
            `,
              }, { headers })
                .then(res => {
                  messageError(res, this)
                  this.idTags.push(res.data.data.createExtendedTags.extendedTag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getAvatars() {
      this.show = true
      const { headers } = this

      axios
        .post('', {
          query: `{
            client(id:"${this.userData.profile.client.id}"){
                  id
                  selectRandomAvatar
                  defaultAvatarImage
                  characterAvatarTags {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }`,
        }, { headers })
        .then(result => {
          messageError(result, this)
          const data = result.data.data.client
          this.selected = []
          this.idTags = []
          this.originalTags = []
          data.characterAvatarTags.edges.forEach(element => {
            this.selected.push(element.node.name)
            this.idTags.push(element.node.id)
            this.originalTags.push(element.node.name)
          })

          this.isRandom = data.selectRandomAvatar
          this.imageSelectedPlaceholder = data.defaultAvatarImage === '' ? null : data.defaultAvatarImage
          this.imageBol = this.imageSelectedPlaceholder != null

          this.show = false
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
  },
}
</script>
<style lang="scss">
#settings_avatar .base-image-avatar {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#settings_avatar .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
#settings_avatar .dropContainer {
  border: 3px dashed rgb(154, 154, 154);
}
#settings_avatar .placeholder:hover {
  background: #e0e0e0;
}

#settings_avatar .image_avatar {
  /* object-fit: scale-down; */
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}
</style>
